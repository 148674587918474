import React, { useState } from "react";
import styled from "styled-components";
import {
  colors,
  breakpoints,
  Section,
  Container,
  Article,
  FullWidthContent,
  Cards,
  H2,
  H3,
  Button,
  LinkButton,
  P,
  Pulse,
  PulseMobile,
} from "styles";
import { Seo, Header, Card, Carousel, Modal } from "components";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  Pharmacy,
  Checkmark,
  Timer,
  Target,
  DrugFree,
  HeatpadBack,
  CEMark,
  ISCP,
} from "assets/svgs";
import { ProductHowTo } from "../../../components/Modals";
import { graphql } from "gatsby";

const BackPage = ({ data }) => {
  const { title, slides, instructions } = data.markdownRemark.frontmatter;
  const [productHowToOpen, setProductHowToOpen] = useState(false);
  return (
    <>
      <Seo title={`${title} | Products`} />
      <Header
        data={data}
        title="Thermacare®<br /> Lower back and hip"
        content="<p>Effective pain relief due to tension and overexertion in the lower back and hip for up to 16 hours</p>"
      />
      <Section>
        <Product>
          <ProductImg>
            <ISCP />
            <CEMark />
            <StaticImage
              src="../../../assets/images/back-pain-relief.png"
              alt="ThermaCare® Neck, shoulder and wrist"
              placeholder="blurred"
              layout="fullWidth"
              loading="lazy"
              objectFit="contain"
              objectPosition="top center"
            />
          </ProductImg>
          <ProductDetails>
            <Title>
              <div>
                <H3>Product Overview</H3>
                <P>
                  <strong>
                    Key benefits of ThermaCare® heat wraps for Lower back and
                    hip:
                  </strong>
                </P>
              </div>
              <Pharmacy className="badge" />
            </Title>
            <P>
              ThermaCare® heat wraps provide powerful targeted drug free pain
              relief for muscle tension, overexertion, sprains and strains and
              arthritis. Suitable for suffers of chronic and occasional pain.
            </P>
            <P>
              ThermaCare® heat wraps uses patented heat cell technology to
              generate deep penetrating heat to the affected area.
            </P>
            <KeyFeatures>
              <ul>
                <li>
                  <Checkmark />
                  Designed specifically to fit the targeted areas thanks to the
                  different shapes available.
                </li>
                <li>
                  <Checkmark />
                  Thin enough to wear discreetly under clothing, flexible enough
                  to be worn on the go or while relaxing.
                </li>
                <li>
                  <Checkmark />
                  Provides effective pain relief, while also comfortable and
                  discreet.
                </li>
                <li>
                  <Checkmark />
                  Temperature consistent during use at 40 degrees (constant
                  temperature for fixed duration).
                </li>
              </ul>
              <ul>
                <li>
                  <Checkmark />
                  Unique Velcro closure system.
                </li>
                <li>
                  <Checkmark />
                  Provides up to 16 hours of pain relief, 8 hours while you wear
                  the wrap and up to 8 hours after you remove the wrap.
                </li>
                <li>
                  <Checkmark />
                  ThermaCare® heat wraps for lower back and hip helps to improve
                  mobility.
                </li>
              </ul>
            </KeyFeatures>
          </ProductDetails>
        </Product>
      </Section>
      <Section $bg={colors.lightGrey}>
        <Container>
          <FullWidthContent>
            <H2 $center style={{ maxWidth: "800px" }}>
              What makes ThermaCare® for lower back and hip unique?
            </H2>
          </FullWidthContent>
          <FullWidthContent>
            <HeatPad>
              <GatsbyImage
                image={getImage(data.thermacareBack)}
                alt="People"
                objectFit="contain"
                objectPosition="bottom right"
              />
            </HeatPad>
          </FullWidthContent>
          <Cards>
            <Card
              svg={<Target />}
              title="Clinically proven pain relief"
              text="Acts locally with no systemic side effects while providing reduction in muscle stiffness and improved flexibility"
            />
            <Card
              svg={<Timer />}
              title="Continuous heat"
              text="Up to 8 hours of continuous deep penetrating heat and up to 16 hours of pain relief"
            />
            <Card
              svg={<DrugFree />}
              title="100% drug free heat wraps"
              text="Compatible with oral drug treatments"
            />
            <Card
              svg={<HeatpadBack />}
              title="Easy to use"
              text="Elastic belt with velcro fastener can be used up to a maximum of 120cm (XXL)"
            />
          </Cards>
        </Container>
      </Section>
      <Section $bg={colors.darkRed}>
        <Instructions $smallPadding>
          <H3 $noMargin $color="white">
            How to use ThermaCare® for neck, shoulder and wrists?
          </H3>
          <Button $bg={colors.orange} onClick={() => setProductHowToOpen(true)}>
            Instructions For Use
          </Button>
        </Instructions>
      </Section>
      <Section>
        <Container $smallPadding>
          <Carousel slides={slides} />
        </Container>
      </Section>
      <Section>
        <Container>
          <FullWidthContent>
            <Article $flipped gap="5rem">
              <GatsbyImage
                image={getImage(data.heatTherapyArticle)}
                alt="Heat therapy"
                objectFit="cover"
                objectPosition="center"
              />
              <div>
                <H2>Find out more about heat therapy</H2>
                <P>
                  Heat therapy is a variant of thermotherapy. As part of
                  physiotherapy, it is used to treat pain and non-inflammatory
                  diseases.
                </P>
                <P>
                  The heat has a relaxing effect on the muscles and can thus
                  help against pain caused by tension.
                </P>
                <LinkButton style={{ maxWidth: "240px" }} to="/heat-therapy/">
                  Find Out More
                </LinkButton>
              </div>
            </Article>
            <Article gap="4rem">
              <GatsbyImage
                image={getImage(data.everydayPainArticle)}
                alt="Everyday pain"
                objectFit="cover"
                objectPosition="center"
              />
              <div>
                <H2>Everyday pain</H2>
                <P>
                  Many people experience muscle and joint pain in everyday life,
                  often as a result of muscle tension and overexertion.
                </P>
                <P>
                  Nevertheless, pain is basically justified because it acts as a
                  warning signal from the body. Pain draws one's awareness to
                  the part of the body that needs attention because there is a
                  problem. Acute pain therefore helps to recognize physical
                  complaints in good time. Chronic pain, on the other hand, is
                  considered a separate clinical picture.
                </P>

                <LinkButton style={{ maxWidth: "240px" }} to="/everyday-pain/">
                  Find Out More
                </LinkButton>
              </div>
            </Article>
          </FullWidthContent>
        </Container>
      </Section>
      <Section>
        <Container>
          <FullWidthContent>
            <ViewAlso>
              <GatsbyImage
                image={getImage(data.viewAlso)}
                alt="ThermaCare®
Neck, shoulder and wrist"
                objectFit="contain"
                objectPosition="center"
              />
              <CEMark />
              <div>
                <span>View also</span>
                <h4>
                  ThermaCare®
                  <br />
                  Neck, shoulder and wrist
                </h4>
                <LinkButton
                  style={{
                    maxWidth: "240px",
                    marginTop: "auto",
                    marginBottom: "2rem",
                  }}
                  to="/products/thermacare-neck-and-shoulders"
                >
                  View Product
                </LinkButton>
              </div>
            </ViewAlso>
          </FullWidthContent>
        </Container>
      </Section>
      {productHowToOpen && (
        <Modal
          isModalOpen={productHowToOpen}
          closeModal={() => setProductHowToOpen(false)}
        >
          <ProductHowTo
            instructions={instructions}
            closeModal={() => setProductHowToOpen(false)}
          />
        </Modal>
      )}
    </>
  );
};

const Title = styled.div`
  display: flex;
  align-item: flex-start;
  justify-content: space-between;
  position: relative;
  div {
    display: flex;
    flex-direction: column;
    width: 80%;
  }
  svg {
    position: absolute;
    height: 50%;
    top: 0;
    right: 1rem;
  }
  ${breakpoints.sm} {
    svg {
      height: 70%;
    }
  }
  ${breakpoints.lg} {
    div {
      width: 100%;
    }
  }
`;

const ViewAlso = styled.div`
  background: #f2f2f2;
  border-radius: 2px;
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr;
  padding: 2rem 1rem 0 1rem;
  position: relative;
  width: 100%;
  span {
    color: #540f16;
  }
  h4 {
    color: #cc112c;
    font-size: 2rem;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
  }
  .gatbsy-image-wrapper {
    position: relative;
  }
  div {
    display: flex;
    flex-direction: column;
  }
  svg {
    position: absolute;
    left: 65%;
    bottom: 50%;
    width: 1.75rem;
  }
  ${breakpoints.sm} {
    padding: 2rem 2rem 0 2rem;
    svg {
      left: 70%;
      bottom: 40%;
    }
  }
  ${breakpoints.md} {
    padding: 2rem 4rem 2.5rem 4rem;
    grid-template-columns: 1fr 1fr;
    h4 {
      margin-top: 1rem;
    }
    svg {
      left: 36%;
      bottom: 1rem;
      width: 2.375rem;
    }
  }
  ${breakpoints.lg} {
    svg {
      left: 40%;
    }
  }
  ${breakpoints.xl} {
    svg {
      left: 37.5%;
    }
  }
`;

const Instructions = styled(Container)`
  align-items: center;
  justify-content: center;
  ${H3} {
    text-align: center;
    grid-column: 1/7;
  }
  ${Button} {
    grid-column: 1/7;
    margin: 1.5em auto;
  }
  ${breakpoints.lg} {
    ${H3} {
      grid-column: 2/9 !important;
    }
    ${Button} {
      grid-column: 9/12 !important;
    }
  }
  @media only screen and (min-width: 1400px) {
    ${H3} {
      font-size: 32px;
      grid-column: 1/9;
    }
    ${Button} {
      grid-column: 9/13;
    }
  }
`;

const HeatPad = styled.div`
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  margin: 3rem auto;
  max-width: 800px;
  position: relative;
  width: 100%;
  .gatsby-image-wrapper {
    width: 100% !important;
  }
  &:after {
    animation: ${PulseMobile} 2s infinite ease-in-out;
    background: rgba(251, 213, 184, 1);
    content: "";
    display: block;
    height: 18%;
    position: absolute;
    left: 28%;
    top: 12%;
    width: 7.5%;
    z-index: 3;
    border-radius: 5rem;
    ${breakpoints.sm} {
      animation: ${Pulse} 2s infinite ease-in-out;
    }
  }
`;

const KeyFeatures = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr;
  margin-top: 3rem;
  width: 100%;
  ul {
    font-weight: 700;
    li {
      align-items: flex-start;
      color: ${colors.brown};
      display: flex;
      font-size: 1rem;
      line-height: 1.4em;
      margin-bottom: 1em;
      svg {
        flex: 0 0 auto;
        height: 1.25rem;
        margin-right: 1rem;
        width: 1.25rem;
      }
    }
  }
  ${breakpoints.sm} {
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr;
    ul {
      li {
        svg {
          height: 1.5rem;
          margin-right: 1rem;
          min-width: 2rem;
          width: 2rem;
        }
      }
    }
  }
`;

const ProductDetails = styled.article`
  position: relative;
  grid-column: 1/7;
  ${breakpoints.sm} {
    padding: 0 4rem;
  }
  ${breakpoints.md} {
    padding: 0 2rem;
    grid-column: 3/7;
  }
  ${breakpoints.lg} {
    grid-column: 5/12;
  }
`;

const ProductImg = styled.div`
  grid-column: 1/7;
  padding: 2rem;
  margin-bottom: 2rem;
  position: relative;
  .gatsby-image-wrapper {
    height: 100% !important;
    margin: 0 auto;
    max-width: 400px;
    width: 100% !important;
  }
  svg {
    position: absolute;
    z-index: 2;
  }
  svg:first-of-type {
    bottom: 0;
    left: 3rem;
    max-width: 7rem;
  }
  svg:last-of-type {
    bottom: 0;
    right: 6rem;
    width: 2.375rem;
  }

  ${breakpoints.sm} {
    grid-column: 2/6;
    svg:first-of-type {
      bottom: 0;
      left: 4rem;
      max-width: 8.375rem;
    }
    svg:last-of-type {
      bottom: 0;
      right: 8rem;
    }
  }
  ${breakpoints.md} {
    grid-column: 1/3;
    padding: 0;
    margin-top: -1rem;
    svg:first-of-type {
      bottom: 8rem;
      left: 1rem;
    }
    svg:last-of-type {
      bottom: 8rem;
      right: 4rem;
    }
  }
  ${breakpoints.lg} {
    grid-column: 2/5;
    svg:first-of-type {
      bottom: 11rem;
      left: 1rem;
    }
    svg:last-of-type {
      bottom: 11rem;
      right: 4rem;
    }
  }
  ${breakpoints.xl} {
    svg:first-of-type {
      bottom: 6rem;
      left: 1rem;
    }
    svg:last-of-type {
      bottom: 6rem;
      right: 4rem;
    }
  }
`;

const ModalBody = styled.div`
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  padding: 1.25rem;
  padding-top: 5rem;
  padding-right: 1.5rem;
  position: relative;
  .gatsby-image-wrapper {
    position: absolute;
    width: 100%;
    z-index: 1;
    &:first-of-type {
      top: 0;
      right: 0;
    }
    &:nth-of-type(2) {
      bottom: 0;
      right: 0;
    }
  }
  h5 {
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 0;
    position: relative;
    z-index: 3;
  }
  h1 {
    font-size: 2.5rem;
    line-height: 3rem;
    position: relative;
    z-index: 3;
  }
  ul {
    margin-top: 1.5rem;
    margin-left: 1.5rem;
    list-style: none;
    position: relative;
    z-index: 3;
    li {
      list-style-type: none;
      position: relative;
      margin-bottom: 1rem;
      padding-left: 0.25rem;
      font-size: 0.875rem;
      ul {
        li {
          margin-bottom: 0.25rem;
          padding-left: 0;
          &:before {
            content: "";
            position: absolute;
            left: -1.5rem;
            top: 0.5rem;
            height: 0.3rem;
            width: 0.3rem;
            background: white;
            border-radius: 50%;
          }
        }
      }
      &:before {
        content: "";
        position: absolute;
        left: -1.5rem;
        top: 0.25rem;
        height: 0.75rem;
        width: 0.75rem;
        background: ${colors.orange};
        border-radius: 50%;
      }
    }
  }
  ${breakpoints.sm} {
    padding: 3rem;
    ul {
      li {
        margin-bottom: 1em;
      }
    }
  }
  ${breakpoints.md} {
    h5 {
      font-size: 2rem;
      line-height: 5rem;
    }
    h1 {
      font-size: 4rem;
      line-height: 5rem;
    }
    ul {
      li {
        margin-bottom: 2.5rem;
        padding-left: 1rem;
        font-size: 1.25rem;
      }
    }
  }
`;

const Product = styled(Container)``;

export default BackPage;

export const query = graphql`
  query BackpainQuery {
    markdownRemark(frontmatter: { productId: { eq: 1 } }) {
      frontmatter {
        productId
        title
        slides {
          id
          step
          title
          image {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          text
        }
        instructions {
          title
          supTitle
          content
        }
      }
    }
    topFlame: file(relativePath: { eq: "background-top-flame.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
    bottomFlame: file(relativePath: { eq: "background-bottom-flame.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
    headerImage: file(relativePath: { eq: "tc-back-product.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
    viewAlso: file(relativePath: { eq: "back-pain-relief.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 346, height: 239, quality: 90)
      }
    }
    thermacareBack: file(relativePath: { eq: "thermacare-back.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
    heatTherapyArticle: file(
      relativePath: { eq: "heat-therapy-article-image.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
    everydayPainArticle: file(
      relativePath: { eq: "everyday-pain-article-image.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
  }
`;
